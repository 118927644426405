import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "char1",
    "char2",
    "char3",
    "char4",
    "char5",
    "char6",
    "form",
    "submitButton"
  ]

  inputWasUpdated(event) {
    // If tab was pressed we don't want to do anything to get in the way of the flow of the user
    // interacting with the form.
    if ((event.keyCode || event.which) == 9) return

    // If the user pastes a 6 digit TOTP into any of the fields we'll fill out all 6 fields
    // and submit the form.
    if (event.currentTarget.value.match(/^\d{6}$/)) {
      this.assignTotp(event.currentTarget.value)
    } else {
      this.sanitizeInput(event.currentTarget)
    }

    // If the value that was input was not valid, we do not need to attempt to do any of the
    // below. Instead, we need to wait for the user to put valid input in.
    if (!event.currentTarget.value) return

    if (this.allTargetsArePopulated()) {
      this.submitFormIfAllInputsAreValid()
    } else {
      this.moveFocusToNextInput(event.currentTarget)
    }
  }

  allTargets() {
    return [
      this.char1Target,
      this.char2Target,
      this.char3Target,
      this.char4Target,
      this.char5Target,
      this.char6Target
    ]
  }

  allTargetsArePopulated() {
    return this.allTargets().every((element) => this.inputIsPopulated(element))
  }

  assignTotp(totpString) {
    this.allTargets().forEach((element, index) => element.value = totpString[index])
  }

  inputIsPopulated(inputElement) {
    return !isNaN(parseInt(inputElement.value))
  }

  moveFocusToNextInput(element) {
    const index = this.allTargets().indexOf(element)
    if (index == this.allTargets().length) return

    this.allTargets()[index + 1].focus()
  }

  sanitizeInput(inputElement) {
    if (!this.inputIsPopulated(inputElement)) return

    const valueAsInt = parseInt(inputElement.value)
    // We only want single digits between 0 and 9. Anything else should be removed.
    if (valueAsInt < 0 || valueAsInt > 9) {
      inputElement.value = ""
    }
  }

  submitFormIfAllInputsAreValid() {
    if (!this.allTargetsArePopulated()) return

    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.value = "Submitting..."
    this.formTarget.submit()
  }
}
